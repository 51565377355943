$primary-400: #00a6d6;
$dark-blue: #1f3037;

.bg-primary-400 {
  background-color: $primary-400 !important;
}

.text-accent-a {
  color: $dark-blue !important;

  &.subtext {
    font-size: 90%;
  }
}

.btn-brand {
  background: $primary-400;
  border-color: $primary-400;
}

.btn-social {
  align-items: center;
  background-color: $dark-blue;
  border: 0;
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 36px;
  margin-bottom: 1rem;
  width: 224px;
}
